<template>
  <li
    tabindex="0"
    @keypress.enter="goToPayment"
    @keypress.space="goToPayment"
    :class="`playlist__bottle-item playlist__bottle-item-${bottleIndex}`"
    @click="goToPayment"
  >
    <video class="playlist__bottle-item__video" playsinline autoplay muted loop>
      <source :src="bottle?.movie || butla" type="video/mp4" />
    </video>
    <h2 class="playlist__bottle-item__heading">{{ bottle.name }}</h2>
  </li>
</template>

<script setup>
import butla from '../assets/movies/bottle.mp4';
import { useRouter } from 'vue-router';
import { useBottleStore } from '../store/bottleStore';

const router = useRouter();
const bottleStore = useBottleStore();

const { bottleIndex, bottle } = defineProps({
  bottleIndex: {
    type: Number,
    required: true,
  },
  bottle: {
    required: true,
    type: Object,
  },
});

function goToPayment() {
  bottleStore.setBottleData(bottle);
  router.push('/payment');
}
</script>

<style scoped>
.playlist__bottle-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  height: auto;
  justify-content: center;
  text-align: center;
  width: 100%;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 0;
}

.playlist__bottle-item-0 {
  animation: playlist__fade-out 0s;
}

.playlist__bottle-item-1 {
  animation: playlist__fade-out 1s;
}

.playlist__bottle-item-2 {
  animation: playlist__fade-out 2s;
}

.playlist__bottle-item__video {
  width: 100%;
  height: auto;
  max-width: 150px;
}

.playlist__bottle-item__heading {
  font-size: 20px;
  font-weight: 600;
  color: #06001c;
  text-transform: uppercase;
  letter-spacing: 1px;
  max-width: 150px;
}


@keyframes playlist__fade-out {
  0% {
    opacity: 0;
    transform: translateY(50%);
    mix-blend-mode: multiply;
  }
  10% {
    opacity: 0.05;
  }
  20% {
    opacity: 0.05;
  }
  30% {
    opacity: 0.1;
  }
  40% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
    transform: translateY(0);
    mix-blend-mode: multiply;
  }
}

@media (max-width: 992px) {
  .playlist__bottle-item {
    margin-bottom: 56px;
  }
}
</style>
