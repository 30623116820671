<template>
  <div class="thank-you">
    <div class="thank-you__wave">
      <Wave :no-margin="true" />
    </div>

    <main class="thank-you__main">

      <form @submit.prevent="sendEmail" class="thank-you__form">
        <h1 class="thank-you__title">Subscribe to our newsletter!</h1>
        <input placeholder="Your email" v-model="formText" class="thank-you__email-input" type="text" />

        <div class="thank-you__subscribe-btn">
          <MainButton :full-width="true">
            <span class="thank-you__subscription-btn-text span-fade-in">
              Subscribe
            </span>
          </MainButton>
        </div>

      </form>
    </main>

    <div class="thank-you__back-btn">
      <MainButton variant="outline" :full-width="true" @click="backToMain">
        <span class="thank-you__back-btn-text span-fade-in">
          BACK TO MAIN
        </span>
      </MainButton>
    </div>

  </div>
</template>

<script setup>
import Wave from '../components/Wave.vue';
import { useRouter } from 'vue-router'; 
import MainButton from '../components/MainButton.vue';
import { ref } from 'vue';
import emailjs from '@emailjs/browser';

const router = useRouter();

const backToMain = () => {
  router.push('/');
};

const formText = ref('');

const sendEmail = () => {
  const serviceID = 'service_2hyma5s';
  const templateID = 'template_v6ttylo';
  const userID = 'wIweFqJqqcdrC7OkG';

  const templateParams = {
    message: formText.value,
  };

  emailjs
    .send(serviceID, templateID, templateParams, userID)
    .then((response) => {
      formText.value = '';
      alert(`You've subscribed to our newsletter!`);
    })
    .catch((error) => {
      console.error('Error sending feedback:', error);
      alert('Failed to send feedback. Please try again.');
    });
};
</script>
  
<style scoped>
.thank-you__form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20% 0 5%;
}

.thank-you__email-input {
  width: 230px;
  margin: 20px;
}

.thank-you__back-btn-text,
.thank-you__subscription-btn-text {
  position: relative;
  font-family: OutfitLight, sans-serif;
  font-weight: 400;
  z-index: 99999;
  margin: 0;
  letter-spacing: 2px;
  font-size: 18px;
}

input {
  width: 100%;
  padding: 14px 0 14px 30px;
  margin-top: 5px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 50px;
}

.feedback-textarea {
  width: 100%;
  max-width: 600px;
  height: 100px;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 10px;
}

.submit-btn {
  padding: 10px 20px;
  background-color: #06001c;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.submit-btn:hover {
  background-color: #333;
}

.thank-you__back-btn {
  width: 250px;
}

  .thank-you {
    text-align: center;
    padding: 0 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    max-width: 70%;
    margin: auto;
  }
  
  * {
    font-family: OutfitLight, sans-serif;
  }

  .wave > * {
    margin-right: 0px;
  }

  .thank-you__wave {
    margin: 0 auto auto;
  }

  .soundWave {
    margin: 0 auto auto;
  }

  .header {
    margin-bottom: 20px;
  }
  
  .header-icon {
    font-size: 24px;
  }
  
  .separator {
    margin-bottom: 40px;
  }
  
  .separator p {
    font-size: 24px;
    letter-spacing: 0.1em;
    color: #333;
  }
  
  .thank-you__main {
    margin: 0 auto auto auto;
  }

  .thank-you-title {
    font-size: 3.5vmax;
    font-weight: 400;
    color: #06001c;
    letter-spacing: 0.05em;
    margin: 0 auto auto;
    padding-bottom: 10%;
  }
  
  .thank-you__title {
    font-size: 3.2vmax;
    font-weight: 400;
    color: #06001c;
    letter-spacing: 0.05em;
    margin: auto;
    text-wrap: nowrap;
  }

  .thank-you-subtext {
    font-size: 16px;
    color: #06001c;
    max-width: 600px;
    margin: auto auto 40px;
  }
  
  .button-wrapper {
    display: flex;
    justify-content: center;
  }

  .thank-you__subscribe-btn {
    font-size: 18px;
    margin: auto;
    width: 250px;
  }

  @media (max-width: 992px) {
    .soundWave > * {
      width: 100% !important;
    }

    .thank-you-title {
      font-size: 4vmax;
    }

    .thank-you-page {
      padding: 0 24px;
      max-width: 100%;
    }

    .thank-you__email-input {
      width: 230px;

    }

    .thank-you__main {
      margin: 0;
    }

     .thank-you__back-btn {
      margin: auto auto 20% auto;
    } 
  }
  </style>
  