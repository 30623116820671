<template>
  <div class="ls-logo" role="banner" aria-label="Animated logo of liquid sound" >
    <video :class="`ls-logo__video ${getModifierClass()}`" playsinline autoplay muted loop>
      <source :src="Wave" type="video/mp4" />
      Your browser does not support HTML5 video.
    </video>
  </div>
</template>

<script setup>
import Wave from '../assets/movies/logoAnim.mp4';
const { noMargin } = defineProps({
  noMargin: {
    type: Boolean,
    default: false,
    required: false,
  }
})

const getModifierClass = () => {
  const noMarginModifier = 'ls-logo__video_no-margin';
  return noMargin ? noMarginModifier : '';
}

</script>

<style scoped>
.ls-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  animation: ls-logo-fade-out 1s;
}

.ls-logo__video {
  max-width: 180px;
  mix-blend-mode: multiply;
  margin-right: 55px;
}

.ls-logo__video_no-margin {
  margin-right: 0;
}

@keyframes ls-logo-fade-out {
  0% {
    opacity: 0;
    mix-blend-mode: multiply;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    mix-blend-mode: multiply;
  }
}
</style>
