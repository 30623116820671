<template>
  <main ref="mainContent" class="main">
    <SubscriptionModal v-if="showSubscriptionModal" @setShowSubscriptionModal="setShowSubscriptionModal" />             

    <section class="main__content">
      <header class="main__header">
        <Wave class="main__icon" />
      </header>
      
      <SoundWave class="main__sound-gif" variant="lg" />

      <div class="main__btn-group">
        <MainButton class="main__btn main__btn--primary" @click="navigateToLanding">
          <span class="main__btn-text span-fade-in">Create</span>
          <span class="main__btn-text span-fade-in">&nbsp;Fragrance</span>
        </MainButton>

        <MainButton variant="outline" class="main__btn main__btn--outline" @click="displayPlaylist">
          <span class="main__btn-text">store</span>
        </MainButton>
      </div>
    </section>
  </main>
</template>

<script setup>
import { useRouter } from 'vue-router';
import MainButton from '../components/MainButton.vue';
import SubscriptionModal from '../components/SubscriptionModal.vue';
import Wave from '../components/Wave.vue';
import SoundWave from '../components/SoundWaves.vue';
import { onUnmounted, ref } from 'vue';

const router = useRouter();
const showSubscriptionModal = ref(false);
const mainContent = ref(null);

const setAllDescendants = (inert) => {
  const allMainContentDescendants =  mainContent.value.querySelectorAll('*');
  allMainContentDescendants.forEach(descendant => {
    descendant.inert = inert;
  });
}

const subscriptionModalTimeout = setTimeout(() => {
  setAllDescendants(false)

  setShowSubscriptionModal(true);
}, 5000)

const setShowSubscriptionModal = (showState) => {
  if(showState === false) {
    setAllDescendants(false)
  }

  showSubscriptionModal.value = showState;
}

onUnmounted(() => clearTimeout(subscriptionModalTimeout))

function navigateToLanding() {
  router.push('/analyze');
}

function displayPlaylist() {
  router.push('/playlist');
}

</script>

<style scoped>
.main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 24px;
  width: 100%;
  max-width: 70%;
  margin: auto;
  box-sizing: border-box;
}

.main__content {
  position: relative;
  z-index: 1;
  text-align: center;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.pad {
  padding: 0 0 20px 0;
  box-sizing: border-box;
}

.record-header {
  flex-shrink: 0;
}

.main__header {
  font-size: 6vmax;
  margin: auto auto 0 auto;
  font-weight: 100;
  height: 150px;
}

.main__icon {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  display: inline-block;
  opacity: 0;
  filter: blur(4px);
  animation: fade-in 0.8s 1.7s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

.main__icon  > * {
  margin: 0;
  max-width: 1000px;
}

.main__sound-gif {
  margin: auto;
}

.main__sound-gif > * {
  opacity: 1 !important;
  animation: sound-fade-in 0.1s !important;
}

.main__icon  > * {
  margin-right: 0px;
}

.main__btn-group {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 0 auto auto auto;
}

.main__btn-text {
  letter-spacing: 2px;
  animation: span-fade-in 2s;
}

.main__btn {
  animation: sound-fade-in 1.5s;
}

@keyframes sound-fade-in {
  0% {
    opacity: 0;
    mix-blend-mode: multiply;
  }
  35% {
    opacity: 0;
    mix-blend-mode: multiply;
    scale: 1.3;
  }
  100% {
    opacity: 1;
    mix-blend-mode: multiply;
    scale: 1;
  }
}

@media (max-width: 992px) {
  .title {
    font-size: 4vmax;
    font-weight: 992;
  }

  .main__sound-gif {
    margin: 7% auto 30%;
  }

  .main__sound-gif > * {
    width: 100% !important;
  }

  .main {
    padding: 24px 24px 0;
    max-width: unset;
    box-sizing: border-box;
  }

  .main__icon  > * {
    margin: 0;
    max-width: 300px;
  }
}
</style>
