<template>
  <div class="customize-page">
    <video playsinline autoplay muted loop id="bgVideo">
      <source :src="videoSrc" type="video/mp4" />
      Your browser does not support HTML5 video.
    </video>
    <LSHeader :navigateTo="goBack" :url="goBackUrl"/>

    <main>
      <h1 class="title">CUSTOMIZE YOUR LIQUID SOUND</h1>

      <div class="content">
        <BottleOptionsCard
          @update:selectedCombination="handleSelection"
          class="showIn"
        />
  
        <div class="bottle-image">
          <img :src="currentBottle" alt="Bottle" />
          <div class="bottle-image-textDown">
            <span class="span-fade-in" :style="{ color: nameColor }">{{
              bottleTitle
            }}</span>
          </div>
  
          <img class="bottle-image-logo" :src="logo" alt="liquid sound logo" />
        </div>
  
        <TitleAndColorCard
          class="showIn titleColorCard"
          @handleColorChange="handleColorChange"
          @handleTitleChange="handleTitleChange"
        />
        <MainButton class="purchaseBtn" @click="goToThankYou">
          <p class="mainBtnContent">
            <span class="span-fade-in">PURCHASE</span>
            <span class="span-fade-in">&nbsp;NOW</span>
          </p>
        </MainButton>
      </div>
    </main>

  </div>
</template>

<script setup>
import BottleOptionsCard from '../components/BottleOptionsCard.vue';
import TitleAndColorCard from '../components/TitleAndColorCard.vue';
import butla from '../assets/images/bottleVariants/whiteSilver.png';
import logo from '../assets/images/logox1.png';
import videoSrc from '../assets/movies/waterBG.mp4';
import MainButton from '../components/MainButton.vue';
import BowweFooter from '@/components/BowweFooter.vue';
import LSHeader from '@/components/LSHeader.vue';

import { ref } from 'vue';
import { useRouter } from 'vue-router';

const bottleTitle = ref('Name Your Fragrance');
const nameColor = ref('#000');
const router = useRouter();
const currentBottle = ref(butla);
const handleTitleChange = newTitle => {
  bottleTitle.value = newTitle;
};

const goBackUrl = '/scent-breakdown'

function handleSelection(path) {
  console.log('Selected Bottle:', path);
  currentBottle.value = path;
}

function goToThankYou() {
  router.push('/thank-you');
}

const handleColorChange = newColor => {
  nameColor.value = newColor;
};

function goBack() {
  router.push(goBackUrl);
}
</script>

<style scoped>

main {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.customize-page {
  text-align: center;
  padding: 10px 24px;
  max-width: 70%;
  display: flex;
  flex-direction: column;
  margin: auto;
  height: 100%;
  overflow: hidden;
  box-sizing: border-box;
}

#bgVideo {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 100%;
  min-height: 100vh;
  transform: translate(-50%, -50%);
  z-index: -1;
  object-fit: cover;
  opacity: 0.3 !important;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.header-icon {
  font-size: 24px;
}

.title {
  font-size: 36px;
  color: #1d1d1f;
  letter-spacing: 0.05em;
  margin: auto auto 40px auto;
  text-wrap: nowrap;
}

.content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: auto;
  gap: 5%;
}

.content > * {
  flex: 1;
}

.bottle-image img {
  width: 100%;
  height: auto;
  animation: resize-width 2s;
}

.bottle-image {
  position: relative;
  mix-blend-mode: multiply;
  animation: slide-in 2s;
}

.bottle-image-textDown {
  position: absolute;
  bottom: 20%;
  left: 50%;
  transform: translateX(-50%);
  font-family: OutfitLight, sans-serif;
  font-weight: 100;
  font-size: 20px;
  word-break: break-word;
}

img.bottle-image-logo {
  position: absolute;
  bottom: 45%;
  left: 50%;
  width: 70% !important;
  mix-blend-mode: multiply;
  transform: translate(-50%, 50%);
  animation: slide-in-logo 2s;
}

.bottle-image-textDown span {
  font-size: 18px;
}

.bottle-image-textUp {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translateX(-50%);
  font-family: OutfitLight, sans-serif;
  font-weight: 600;
  font-size: 24px;
  word-break: break-word;
}

.bottle-image-textUp span {
  font-family: 'OutfitRegular', sans-serif;
  font-weight: 400;
}

@keyframes slide-in-logo {
  0% {
    opacity: 0;
    width: 0%;
  }

  50% {
    opacity: 0;
    width: 0%;
  }

  100% {
    width: 20%;
    opacity: 1;
  }
}

@keyframes resize-width {
  0% {
    width: 0;
  }

  100% {
    width: 100%;
  }
}

@keyframes slide-in {
  0% {
    transform: translateY(150%);
    opacity: 0;
    width: 0%;
  }

  50% {
    opacity: 0;
    width: 0%;
  }

  100% {
    transform: translateY(0%);
    width: 100%;
    opacity: 1;
  }
}

span {
  display: inline-block;
  opacity: 0;
  filter: blur(4px);
}

span:nth-child(1) {
  animation: fade-in 0.8s 2.25s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

span:nth-child(2) {
  animation: fade-in 0.8s 2.5s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

span:nth-child(3) {
  animation: fade-in 0.8s 2.75s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

span:nth-child(4) {
  animation: fade-in 0.8s 3s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

span:nth-child(5) {
  animation: fade-in 0.8s 3.25s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

span:nth-child(6) {
  animation: fade-in 0.8s 3.5s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

@keyframes fade-in {
  100% {
    opacity: 1;
    filter: blur(0);
  }
}

@keyframes show-in {
  0% {
    opacity: 0;
    transform: translateY(20%);
  }

  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

.showIn {
  animation: show-in 1s;
}

.purchaseBtn {
  display: none;
}

@media (max-width: 1200px) {
  @keyframes slide-in {
    0% {
      transform: translateY(150%);
      opacity: 0;
      width: 0%;
    }

    50% {
      opacity: 0;
      width: 0%;
    }

    100% {
      transform: translateY(0%);
      width: 50%;
      opacity: 1;
    }
  }

  .customize-page {
    height: auto;
    max-width: 100%;
  }

  .content {
    flex-direction: column;
  }

  .bottle-image {
    order: -1;
    width: 50%;
  }

  .titleColorCard {
    order: -1;
  }

  .purchaseBtn {
    display: block;
    margin: 20px auto;
    padding: 14px 36px;
  }
}

@media (max-width: 992px) {
  @keyframes slide-in {
    0% {
      transform: translateY(150%);
      opacity: 0;
      width: 0%;
    }

    50% {
      opacity: 0;
      width: 0%;
    }

    100% {
      transform: translateY(0%);
      width: 70%;
      opacity: 1;
    }
  }

  .title {
    font-size: 3.5vmax;
    margin: 30px 0;
  }

  .options-card {
    margin: 20px 0;
  }

  .bottle-image {
    margin: 20px 0;
    width: 70%;
  }

  .notes-section > * {
    width: 75%;
  }

  .customizeBtnWrapper {
    margin-bottom: 20px;
  }
}

@media (max-width: 576px) {
  @keyframes slide-in {
    0% {
      transform: translateY(150%);
      opacity: 0;
      width: 0%;
    }

    50% {
      opacity: 0;
      width: 0%;
    }

    100% {
      transform: translateY(0%);
      width: 80%;
      opacity: 1;
    }
  }

  .bottle-image {
    margin: 20px 0;
    width: 80%;
  }

  .bottle-image-textUp {
    font-size: 22px;
  }

  .bottle-image-textDown {
    font-size: 16px;
  }

  .title {
    font-size: 1.9vmax;
    text-wrap: nowrap;
  }
}
</style>
