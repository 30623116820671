<template>
  <button class="navigation-btn" @click="handleNavigation">
    <a :href="url" class="navigation-btn__link">
      <span v-if="!arrowHidden" class="navigation-btn__arrow">← </span>
      <span class="navigation-btn__text">{{ alternativeText ?? 'BACK' }}</span>
    </a>
  </button>
</template>

<script setup>
const { navigateTo, alternativeText, arrowHidden, url } = defineProps([
  'navigateTo',
  'alternativeText',
  'arrowHidden',
  'url',
]);

const handleNavigation = e => {
  navigateTo();
  e.preventDefault();
};
</script>

<style scoped>
.navigation-btn__link {
  text-wrap: nowrap;
  font-size: 18px;
  color: #000;
  text-decoration: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
  z-index: 999;
  text-decoration: none;
  color: unset;
}

.navigation-btn__text {
  font-weight: 600;
}

.navigation-btn {
  border: none;
  background: none;
}

@media (max-width: 768px) {
  .navigation-btn__text:last-child {
    display: none;
  }

  .navigation-btn__arrow {
    font-size: 36px;
  }

  .navigation-btn__text {
    font-size: 12px;
  }
}
</style>
