<template>
  <main class="progress">
    <header class="progress__title">
      <span class="span-fade-in">&nbsp;WHAT</span>
      <span class="span-fade-in">&nbsp;IS</span>
      <span class="span-fade-in">&nbsp;THE</span>
      <span class="span-fade-in">&nbsp;SCENT</span>
      <span class="span-fade-in">&nbsp;OF</span>
      <span class="span-fade-in">&nbsp;SOUND?</span>
    </header>

    <div class="progress__container">
      <div class="progress__numbers">
        <div class="progress__odometer">{{ percentage }}</div>
        <span class="progress__percentage span-fade-in">%</span>
      </div>

      <div class="progress__progress-bar-container">
        <div class="progress__progress-bar"></div>
      </div>
    </div>
  </main>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import '../styles/textFadeIn.css';
const percentage = ref(0);
const router = useRouter();
import Odometer from 'odometer/odometer.js';
import 'odometer/themes/odometer-theme-default.css';

onMounted(() => {
  const odometer = new Odometer({
    el: document.querySelector('.progress__odometer'),
  });

  setTimeout(() => {
    odometer.update(99);
  }, 600);
  setTimeout(() => odometer.update(100), 2750);
  setTimeout(() => {
    router.push('/main');
  }, 4000);
});
</script>

<style scoped>
.progress {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  text-align: center;
  padding: 0 24px;
}

.progress__progress-bar {
  height: 100%;
  background-color: #000;
  transition: width 0.2s ease-in-out;
  width: 100%;
  animation: progress 3.5s;
  border-radius: 10px;
}

.progress__odometer {
  opacity: 1 !important;
  font-size: 24px;
  margin-left: -1px;
  font-weight: 600;
}

.progress__numbers {
  display: flex;
  align-items: center;
  justify-content: center;
}

.progress__percentage {
  font-size: 24px;
  font-family: OutfitLight, sans-serif;
  font-weight: 600;
}

.progress__title {
  font-size: 64px;
  font-weight: 600;
  letter-spacing: 0.05em;
  color: #06001c;
  margin-bottom: 200px;
}

.progress__container {
  position: fixed;
  bottom: 0;
  width: 100%;
  text-align: center;
  padding: 0 20px;
  margin-bottom: 96px;
}

.progress-number {
  font-size: 18px;
  margin-bottom: 10px;
  color: #06001c;
  font-weight: 600;
}

.progress__progress-bar-container {
  width: 60%;
  height: 4px;
  background-color: #e0e0e0;
  position: relative;
  display: flex;
  justify-content: center;
  margin: auto;
  border-radius: 10px;
}

@keyframes progress {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}

@media (max-width: 992px) {
  .progress__title {
    font-size: 32px;
  }
}
</style>
