<template>
  <div class="playlist">
    <div class="playlist__header">
      <LSHeader  :navigateTo="goBack" :url="goBackUrl"/>
    </div>

    <main class="playlist__main">
      <h1 class="playlist__title">PLAYLIST</h1>

      <BottleList :bottles="bottles" />

      <div class="playlist__soundWave">
        <SoundWave style="height: 100%;" variant="lg" />
      </div>
    </main>
  </div>
</template>

<script setup>
import BottleList from '../components/BottleList.vue';
import SoundWave from '../components/SoundWaves.vue';
import shibuya from '../assets/songs/shib.mp3';
import jung from '../assets/songs/jung.mp3';
import breeze from '../assets/songs/breeze.mp3';
import shibMov from '../assets/movies/shibMov.mp4';
import jungMov from '../assets/movies/jungMov.mp4';
import breezeMov from '../assets/movies/breezeMov.mp4';
import { useRouter } from 'vue-router';
import LSHeader from '@/components/LSHeader.vue';

const bottles = [
  {
    name: 'Noise Pollution',
    topNotes: ['Birch Tar', 'Black Pepper'],
    middleNotes: ['Labdanum', 'Patchouli '],
    musicNotes: ['F5', 'C#4', 'D#6', 'D8'],
    price: 130,
    edition: '30/50',
    size: '50mm/1.7 fl oz',
    quantity: '01',
    song: jung,
    movie: jungMov,
  },
  {
    name: 'Shibuya Crossing',
    topNotes: ['Pink Pepper', 'Saffron'],
    middleNotes: ['Amber', 'Vanilla'],
    musicNotes: ['B4', 'E4', 'A#6', 'C#7'],
    price: 130,
    edition: '30/50',
    size: '50mm/1.7 fl oz',
    quantity: '01',
    song: shibuya,
    movie: shibMov,
  },
  {
    name: 'Namibian Breeze',
    topNotes: ['Rose', 'Ylang-Ylang'],
    middleNotes: ['Green Tea', 'Cypress'],
    musicNotes: ['C#2', 'G#2', 'F3', 'C4'],
    price: 130,
    edition: '30/50',
    size: '50mm/1.7 fl oz',
    quantity: '01',
    song: breeze,
    movie: breezeMov,
  },
];
const router = useRouter();

const goBackUrl = '/main'

function goBack() {
  router.push(goBackUrl);
}
</script>

<style scoped>
.playlist {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100% - 48px);
  min-height: fit-content;
  max-width: 70%;
  margin: auto;
  padding: 24px;
}

.playlist__title {
  margin: 0 0 auto 0;
  font-size: 48px;
  font-weight: 600;
  letter-spacing: 0.05em;
  color: #1d1d1f;
  margin-bottom: 60px;
}

.playlist__main {
  margin: auto;
}

.playlist__header {
  margin-bottom: auto;
  width: 100%;
}

.playlist__soundWave {
  margin: 0 0 auto 0;
  opacity: 0.1 !important;
}

@media (max-width: 1200px) {
  .playlist__title {
    margin: 60px 0;
  }
}

@media (max-width: 576px) {
  .playlist {
    max-width: unset;
  }

  .playlist__soundWave {
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    height: 150px;
    mix-blend-mode: multiply;
    width: 100%;
  }
}
</style>
