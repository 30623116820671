<template>
  <div role="alert" class="modal-overlay" @click.self="closeModal">
    <article class="modal-content">
      <button class="close-button" @click="closeModal">
        <img :src="closeIcon" alt="close subcription modal icon" />
      </button>

      <section class="modal-content-inner" v-if="!subscribed">
        <h2 class="modal-title">STEP INTO THE SCENT OF SOUND</h2>
        <p class="modal-description">
          Subscribe for exclusive access to Liquid Sound
        </p>

        <input
          placeholder="TYPE YOUR EMAIL"
          v-model="formText"
          id="bottle-title"
          ref="emailInput"
          type="text"
        />

        <MainButton class="btn" @click="sendEmail"
          ><span>SUBSCRIBE</span></MainButton
        >
      </section>

      <section class="modal-content-inner-altern" v-else>
        <h2 class="modal-title">THANK YOU FOR YOUR SUBSCRIBTION</h2>
        <MainButton class="btn" @click="backToMain"
          ><span>BACK TO MAIN</span></MainButton
        >
      </section>

      <img id="waterSplash" :src="waterSplash" alt="" />
    </article>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted, onBeforeMount } from 'vue';
import MainButton from './MainButton.vue';
import closeIcon from '../assets/images/x.png';
import waterSplash from '../assets/movies/waterSpash.gif';
import { useRouter } from 'vue-router';
import emailjs from '@emailjs/browser';

const emit = defineEmits(['setShowSubscriptionModal'])

function setCookie(name, value, days) {
  const date = new Date();
  date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
  document.cookie = `${name}=${value};expires=${date.toUTCString()};path=/`;
}

function getCookie(name) {
  const nameEQ = `${name}=`;
  const cookies = document.cookie.split(';');
  for (let cookie of cookies) {
    cookie = cookie.trim();
    if (cookie.startsWith(nameEQ)) {
      return cookie.substring(nameEQ.length);
    }
  }
  return null;
}

const router = useRouter();

const subscribed = ref(false);
const formText = ref('');
const emailInput = ref(null)
let previousActiveElement;

const backToMain = () => {
  router.push('/');
};

const sendEmail = () => {
  const serviceID = 'service_2hyma5s';
  const templateID = 'template_v6ttylo';
  const userID = 'wIweFqJqqcdrC7OkG';

  const templateParams = {
    message: formText.value,
  };

  emailjs
    .send(serviceID, templateID, templateParams, userID)
    .then(() => {
      formText.value = '';
      subscribed.value = true;
      setCookie('subscribed', 'true', 30);
    })
    .catch(error => {
      console.error('Error sending feedback:', error);
      alert('Failed to send feedback. Please try again.');
    });
};

const closeModal = () => {
  emit('setShowSubscriptionModal', false);
};

onMounted(() => {
  emailInput.value?.focus()
});

onBeforeMount(() => {
  if (getCookie('subscribed') === 'true') {
    emit('setShowSubscriptionModal', false);
  }

  previousActiveElement = document.activeElement;
});

onUnmounted(() => {
  previousActiveElement.focus();
});

</script>

<style scoped>
.btn,
input {
  max-width: 230px;
  width: 100%;
}

input {
  padding: 14px 0 14px 0;
  text-align: center;
  margin-top: 5px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 50px;
  margin: 20px 0 12px;
  box-sizing: border-box;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100.5%;
  height: 100.5%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

#waterSplash {
  position: absolute;
  bottom: -19%;
  width: 275px;
  max-height: 200px;
  z-index: 0;
}

.modal-content {
  margin: 0 10px;
  aspect-ratio: 270/280;
  background: #fff;
  border-radius: 12px;
  min-width: 280px;
  min-height: 360px;
  max-width: 350px;
  text-align: center;
  position: relative;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal-content-inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 90%;
}

.modal-content-inner-altern {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  margin: auto auto 15%;
  gap: 24px;
  max-width: 90%;
}

.modal-content > * {
  z-index: 1;
}

.close-button {
  margin: 14px auto 10px;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

.modal-title {
  font-size: 1.8rem;
  margin: 0;
  font-weight: 100;
  color: #333;
  letter-spacing: 2px;
}

.modal-description {
  font-size: 0.75rem;
  color: #666;
  margin-bottom: 0;
}
</style>
