<template>
  <div class="notes-container">
    <img
      class="info-icon"
      :src="infoIcon"
      alt="Info Icon"
      @mouseover="showInfo = true"
      @mouseleave="showInfo = false"
    />

    <div v-if="!showInfo">
      <section class="note-section">
        <h2 class="note-title">MUSIC ESSENCE</h2>
        <ul class="note-content">
          <li v-for="note in musicNotes" :key="note">&nbsp;{{ note }}</li>
        </ul>
      </section>
      <section class="note-section">
        <h2 class="note-title">TOP NOTES</h2>
        <ul class="note-content">
          <li v-for="note in topNotes" :key="note"> &nbsp;/{{ note }} </li>
        </ul>
      </section>
      <section class="note-section">
        <h2 class="note-title">MIDDLE NOTES</h2>
        <ul class="note-content">
          <li v-for="note in middleNotes" :key="note"> &nbsp;/{{ note }}</li>
        </ul>
      </section>
    </div>

    <div v-else>
          <p>
            Sound frequencies vary by input and environment, so the Liquid Sound algorithm may produce unique results each time, adding to the personalized experience.
          </p>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import infoIcon from '../assets/images/info.png';

const showInfo = ref(false);

const props = defineProps({
  musicNotes: {
    type: Array,
    required: true,
  },
  topNotes: {
    type: Array,
    required: true,
  },
  middleNotes: {
    type: Array,
    required: true,
  },
});
</script>

<style scoped>
.notes-container {
  border: 1px solid #f0f0f0;
  border-radius: 20px;
  padding: 35px;
  min-height: 250px;
  max-height: 250px;
  width: 100%;
  background-color: #fff;
  text-align: left;
  opacity: 0.8;
  position: relative;
  overflow: hidden;
}

ul {
  display: flex;
  flex-wrap: nowrap;
  text-wrap: nowrap;
  list-style-type: none;
  padding: 0;
}

ul li::after {
  content: ',';
}

ul li:last-child::after {
  content: '';
}

li::marker {
  display: none;
}
.note-section {
  margin-bottom: 20px;
}

.note-title {
  font-size: 18px;
  font-weight: 400;
  color: #c4c4c4;
  margin: 0;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}

.note-content {
  font-size: 20px;
  font-weight: 600;
  margin-top: 5px;
  color: #000;
  letter-spacing: 0.05em;
  font-family: OutfitLight;
}

/* Info Icon Styles */
.info-container {
  position: absolute;
  top: 20px;
  right: 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.info-icon {
  position: absolute;
  right: 0;
  top: 0;
  width: 24px;
  height: 24px;
  transform: translate(-8px, 24px);
}

/* Info Box Styles */
.info-box {
  position: absolute;
  top: 30px;
  right: 0;
  width: 250px;
  background: #fff;
  padding: 12px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  font-size: 14px;
  color: #333;
  z-index: 10;
  overflow-y: auto;
  max-height: 150px;
  text-align: left;
}

@media (max-width: 992px) {
  .notes-container {
    text-align: center;
    min-height: 200px;
    max-height: 200px;
  }

  ul {
    justify-content: center;
  }
}
</style>
