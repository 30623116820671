<template>
  <div class="record">
    <video aria-hidden="true" playsinline muted loop id="bgVideo">
      <source :src="videoSrc" type="video/mp4" />
      Your browser does not support HTML5 video.
    </video>

    <LSHeader :navigate-to="goToMainPage" :url="goBackUrl"/>

    <section class="record__content">
      <h1 id="create-fragrance" class="record__title">
        <span class="span-fade-in">CREATE</span>
        <span class="span-fade-in">&nbsp;YOUR</span>
        <span class="span-fade-in">&nbsp;FRAGRANCE</span>
      </h1>
      <Record ref="record" />
    </section>

    <footer class="record__footer">
      <p>
        To upload music, let's collaborate responsibly. Ensure all licenses are in
        order before applying. Join our creative community and apply today to
        start transforming sound into scents.
      </p>
    </footer>
  </div>
</template>

<script setup>
import { useRouter } from 'vue-router';
import videoSrc from '../assets/movies/wavesBG.mp4';
import Record from '@/components/Record.vue';
import { ref } from 'vue';
import LSHeader from '@/components/LSHeader.vue';

const router = useRouter();
const record = ref(null);

const stopRecording = () => {
  record?.value?.stopRecording?.();
};

const goBackUrl = '/main'

function goToMainPage() {
  router.push(goBackUrl);
  stopRecording();
}

function goToBehind() {
  router.push('/behind');
  stopRecording();
}
</script>

<style scoped>
.record {
  text-align: center;
  max-width: 70%;
  margin: auto;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.nav {
  display: flex;
  align-items: center;
  position: relative;
  justify-content: space-between;
  margin: 20px 0px;
}

.record__content {
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.record-header {
  flex-shrink: 0;
}

.wave {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.wave > * {
  margin: 0;
}

#bgVideo {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 100%;
  min-height: 100vh;
  transform: translate(-50%, -50%);
  z-index: -1;
  object-fit: cover;
  opacity: 0.1 !important;
}

.record__button {
  cursor: pointer;
}

.record-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-icon {
  font-size: 24px;
}

.record__title {
  font-size: 3.3vmax;
  color: #1d1d1f;
  margin-bottom: 40px;
}

.record-icon {
  font-size: 36px;
  color: #000;
}

.record-finished .record-icon {
  color: black;
}

.create-btn {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #06001c;
  color: #fff;
  border-radius: 50px;
  border: none;
  font-size: 16px;
  cursor: pointer;
}

.record__footer {
  color: #666;
  max-width: 65%;
  margin: 40px auto 0;
  font-size: 1vmax;
  font-family: OutfitLight;
}

video {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
}

@media (max-width: 992px) {
  .record__footer {
    max-width: 100%;
    font-size: 1.5vmax;
    margin: 0 0 36px;
  }

  .record {
    padding: 0 24px;
    max-width: 85%;
  }

  .record__title {
    margin: auto auto 0;
  } 
}

@media (max-width: 576px) {
  .record__title {
    font-size: 1.3vmax;
  }
}
</style>
